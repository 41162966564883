import storage from './storage'

const spoofUser = (user) => {
  const realUser = storage.get('user')
  storage.remove('user')
  storage.set('user', user)
  storage.set('isSpoofed', true)
  storage.set('realUser', realUser)
  window.location.reload()
}

const undoSpoofUser = (user) => {
  storage.remove('user')
  storage.remove('real_user')
  storage.set('user', user)
  storage.set('isSpoofed', false)
  window.location.reload()
}

const isSpoofedUser = () => Boolean(storage.get('isSpoofed'))
const userEmail = () => (storage.get('isSpoofed') ? storage.get('realUser').email : storage.get('user').email)

const isUserSuperAdmin = (user) => user.role === 'SUPER_ADMIN'

export {
  spoofUser,
  undoSpoofUser,
  isSpoofedUser,
  userEmail,
  isUserSuperAdmin,
}
