import ReactDOM from 'react-dom'
import React from 'react'
import App from './App'
import './utils/translation'

require('./styles/index.scss')

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <App />,
    document.getElementById('root'),
  );
})
