import React from 'react'
import { AxiosResponse } from 'axios'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

type ShowErrorPropType = {
  error: AxiosResponse
}

function ShowError({ error }:ShowErrorPropType) {
  const errorMessage = () => {
    if (error.status === 404) {
      return 'Not found'
    }
    if (error.status === 403) {
      return "You don't have access to this page"
    }
    return 'Error Loading Data'
  }

  const handleReload = () => {
    window.location.reload()
  }

  return (
    <Box p={4}>
      <Typography variant="subtitle1" align="center">{errorMessage()}</Typography>
      <Typography align="center">
        <Button color="primary" onClick={handleReload}>Retry</Button>
      </Typography>
    </Box>
  )
}

export default ShowError
