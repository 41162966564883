export const GA_GTM_ID = 'GTM-KTZ8MLZ'

export const GTM_PROJECT = 'pmr360'

export const EVENTS = {
  userEvent: 'userEvent',
}

export const EVENT_CATEGORY = {
  participant_profile: 'Participant Profile',
  performance_dashboard: 'Performance Dashboard',
}

export const EVENT_LABEL = {
  participant_profile: 'PMR-642',
  performance_dashboard: 'PMR-710',
  performance_dashboard_feedback_summary: 'PMR-712',
  performance_dashboard_view_details: 'PMR-716',
}

export const EVENT_ACTION = {
  clicked: 'Clicked',
  viewed: 'Viewed',
}
