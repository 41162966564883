import React, { useState, useEffect } from 'react'
import Joyride, { LIFECYCLE, CallBackProps, STATUS } from 'react-joyride'
import { getUserCoachmarks, createUserCoachmarks } from '../../../api/coachmarks'
import theme from '../../../styles/theme'
import { CoachmarkStep } from './CoachMarkTypes'

interface CoachmarkCallback extends CallBackProps {
  step: CoachmarkStep
}

interface Props {
  coachMarkSteps: CoachmarkStep[]
}

const CoachMarks: React.FC<Props> = ({ coachMarkSteps }) => {
  const [run, setRun] = useState(false)
  const [userCoachmarks, setUserCoachmarks] = useState([])

  const isLifecycleInit = (lifecycle: string) => lifecycle === LIFECYCLE.INIT
  const isLifecycleComplete = (lifecycle: string) => lifecycle === LIFECYCLE.COMPLETE

  const userDismissedCoachmarks = userCoachmarks.map((c) => c.is_dismissed && c.coachmark_type)

  const hasUserDismissedCoachmarkStep = (step: CoachmarkStep) => userDismissedCoachmarks.includes(step.coachmarkType)

  const joyrideCallback = (data: CoachmarkCallback) => {
    const { lifecycle, step, status } = data

    if (isLifecycleInit(lifecycle) && (hasUserDismissedCoachmarkStep(step))) {
      setRun(false)
    }

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (isLifecycleComplete(lifecycle) && finishedStatuses.includes(status)) {
      createUserCoachmarks({ coachmark_type: step.coachmarkType, is_dismissed: true })
    }
  }

  useEffect(() => {
    getUserCoachmarks().then((resp) => {
      setUserCoachmarks(resp.coachmarks)
      setRun(true)
    })
  }, [])

  return (
    <Joyride
      steps={coachMarkSteps}
      callback={joyrideCallback}
      disableOverlayClose
      disableScrolling
      disableCloseOnEsc
      locale={{ last: 'Dismiss' }}
      run={run}
      continuous
      floaterProps={{ disableAnimation: true }}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
          zIndex: 2000,
          spotlightShadow: theme.palette.primary.main,
        },
      }}
    />
  )
}

export default CoachMarks
