import React, { useContext, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { isRailsEnvProduction } from '../../constants/RailsEnvironments'
import { ConfigContext } from '../../contexts/config'

function FallbackComponent() {
  const handleReload = () => {
    window.location.reload()
  }

  return (
    <Box p={4}>
      <Typography variant="h4" align="center">Something went wrong, please try again</Typography>
      <Typography align="center">
        <Button color="primary" onClick={handleReload}>Reload</Button>
      </Typography>
    </Box>
  )
}

const ErrorBoundary = ({ children }) => {
  const config = useContext(ConfigContext)

  useEffect(() => {
    if (isRailsEnvProduction(config.RAILS_ENVIRONMENT)) {
      Sentry.init({
        dsn: config.SENTRY_DSN,
      })
    }
  }, [])

  return (
    <Sentry.ErrorBoundary
      fallback={FallbackComponent}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
