/* eslint-disable max-len, no-unused-vars */
import React, {
  useContext, Suspense, lazy,
} from 'react'
import {
  Route, BrowserRouter as Router, Switch, Redirect,
} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import AppLayout from './components/AppLayout'
import ToastProvider from './components/common/Toast'
import Loader from './components/common/Loader'
import { AdminCyclesProvider } from './contexts/AdminCycles'
import { CyclesProvider } from './contexts/cycle'
import { ParticipantProvider } from './contexts/Participant'
import RouteConfigProvider from './contexts/RouteConfig'
import { UserContext } from './contexts/User'
import { ROLE } from './constants'

const Login = lazy(() => import('./components/login'))
const CreateCycle = lazy(() => import('./components/cycles/CreateCycle'))
const AdminPromotionDashboard = lazy(() => import('./components/cycles/AdminPromotionDashboard'))
const EditCycle = lazy(() => import('./components/cycles/EditCycle'))
const CalibrationSessions = lazy(() => import('./components/cycles/CalibrationSessions'))
const EditCalibrationSession = lazy(() => import('./components/cycles/CalibrationSessions/EditCalibrationSession'))
const CalibrationSessionDetails = lazy(() => import('./components/SessionAdmin/CalibrationSessionDetails'))
const CycleListing = lazy(() => import('./components/cycles/CycleListing'))
const PerformanceHome = lazy(() => import('./components/performance/PerformanceHome'))
const CyclePrep = lazy(() => import('./components/performance/CyclePrep'))
const FinalAssessment = lazy(() => import('./components/performance/FinalAssessment'))
const Users = lazy(() => import('./components/users'))
const Audits = lazy(() => import('./components/audits'))
const PeerFinalization = lazy(() => import('./components/performance/PeerFinalization'))
const MyReportees = lazy(() => import('./components/MyReportees'))
const DirectReportPeerFinalization = lazy(() => import('./components/MyReportees/DirectReportPeerFinalization'))
const Feedback = lazy(() => import('./components/performance/SPUFeedback/Feedback'))
const SPUFeedback = lazy(() => import('./components/performance/SPUFeedback'))
const Participants = lazy(() => import('./components/Participants'))
const BulkParticipantFeedbackPDFDownload = lazy(() => import('./components/Participants/BulkParticipantFeedbackPDFDownload'))
const EditParticipant = lazy(() => import('./components/Participants/EditParticipant'))
const EditParticipantRating = lazy(() => import('./components/Participants/EditParticipantRating'))
const EditPromotionDecision = lazy(() => import('./components/Participants/EditParticipantPromotionDecision'))
const CreateParticipant = lazy(() => import('./components/Participants/CreateParticipant'))
const ViewFeedback = lazy(() => import('./components/ViewFeedback/ViewSPUFeedback'))
const ParticipantUpload = lazy(() => import('./components/cycles/ParticipantUpload'))
const ParticipantUpsert = lazy(() => import('./components/cycles/ParticipantUpsert'))
const PopulateParticipantsJobs = lazy(() => import('./components/cycles/PopulateParticipants'))
const ParticipantJobs = lazy(() => import('./components/cycles/ParticipantJobs'))
const UpsertParticipantJobs = lazy(() => import('./components/cycles/UpsertParticipantJobs'))
const QuestionnaireJobs = lazy(() => import('./components/cycles/QuestionnaireJobs'))
const QuestionnaireUpload = lazy(() => import('./components/cycles/QuestionnaireUpload'))
const ParticipantDownload = lazy(() => import('./components/cycles/ParticipantDownload'))
const JSONBuilder = lazy(() => import('./components/cycles/JSONBuilder'))
const ViewQuestions = lazy(() => import('./components/Admin/Questions'))
const Nudges = lazy(() => import('./components/cycles/Nudges'))
const DirectReportViewFeedback = lazy(() => import('./components/MyReportees/DirectReportViewFeedback'))
const DirectReportFeedbackSummary = lazy(() => import('./components/MyReportees/DirectReportFeedbackSummary'))
const ParticipantSummary = lazy(() => import('./components/performance/FinalAssessment/ParticipantSummary'))
const SessionAdminCalibrationSessionsList = lazy(() => import('./components/SessionAdmin/CalibrationSessionsList'))
const SignOffpartyCalibrationSessionsList = lazy(() => import('./components/SignOffParty/CalibrationSessionsList'))
const SignOffPartyCalibrationSessionDetails = lazy(() => import('./components/SignOffParty/CalibrationSessionDetails'))
const EditParticipantPanel = lazy(() => import('./components/Participants/EditParticipantPanel'))
const Reports = lazy(() => import('./components/cycles/DecisionSupport/Reports'))
const PerformanceDashboard = lazy(() => import('./components/PerformanceDashboard'))
const SecondaryReportFeedbackSummary = lazy(() => import('./components/MyReportees/SecondaryReportFeedbackSummary'))
const NominationForm = lazy(() => import('./components/MyReportees/promotions/NominationForm'))
const Endorsement = lazy(() => import('./components/performance/Endorsement'))
const EndorsementForm = lazy(() => import('./components/performance/Endorsement/EndorsementForm'))
const Nominations = lazy(() => import('./components/PromotionsHrPartner/Nominations'))
const HrPartnerCalibrationSessions = lazy(() => import('./components/RatingCalibrationHrPartner/RatingCalibrationSessions'))
const HrPartnerCreateRatingCalibrationSession = lazy(() => import('./components/RatingCalibrationHrPartner/CreateRatingCalibrationSession'))
const HrPartnerEditRatingCalibrationSession = lazy(() => import('./components/RatingCalibrationHrPartner/EditRatingCalibrationSession'))
const CreateSesssion = lazy(() => import('./components/PromotionsHrPartner/CreateSesssion'))
const EditSession = lazy(() => import('./components/PromotionsHrPartner/EditSession'))
const CalibrationSessionDetail = lazy(() => import('./components/PromotionsHrPartner/CalibrationSessionDetail'))
const PanelistPromotionCalibrationSessions = lazy(() => import('./components/PromotionCalibrationPanelist/CalibrationSessions'))
const PanelistCalibrationSessionDetails = lazy(() => import('./components/PromotionCalibrationPanelist/CalibrationSessionDetails'))
const PanelisRatingCalibrationSessions = lazy(() => import('./components/RatingCalibrationPanelist/CalibrationSessions'))
const PanelistRatingCalibrationSessionDetails = lazy(() => import('./components/RatingCalibrationPanelist/CalibrationSessionDetails'))
const SignOffPartyPromotionCalibrationSessions = lazy(() => import('./components/PromotionSignOffParty/CalibrationSessions'))
const SignOffPartyPromotionCalibrationSessionDetails = lazy(() => import('./components/PromotionSignOffParty/CalibrationSessionDetails'))
const ViewEndorsement = lazy(() => import('./components/performance/Endorsement/ViewEndorsement'))
const UploadThermaScanReport = lazy(() => import('./components/cycles/UploadThermaScanReport'))
const PanelistCalibrationRating = lazy(() => import('./components/RatingCalibrationPanelist/CalibrationRating'))
const RealTimeFeedbacks = lazy(() => import('./components/RealTimeFeedbacks'))

const PBPDashboardPeerSelection = lazy(() => import('./components/PBPDashboard/PeerSelection'))
const PBPDashboardFeedback = lazy(() => import('./components/PBPDashboard/Feedback'))

const PageNotFound = () => (
  <Grid container justifyContent="center">
    <p className="mt-10">404 Page Not Found</p>
  </Grid>
)

const AppRouter = () => {
  const { user } = useContext(UserContext)

  const SuperAdminRoutes = () => {
    const isUserSuperAdmin = user.role === ROLE.SUPER_ADMIN
    return (
      <>
        {isUserSuperAdmin ? (
          <>
            <Route path="/app/admin">
              <AdminCyclesProvider>
                <Switch>
                  <Route path="/app/admin/cycles" exact component={CycleListing} />
                  <Route path="/app/admin/cycles/:cycleId/calibration-sessions/:id/edit" exact component={EditCalibrationSession} />
                  <Route path="/app/admin/calibration-sessions" exact component={CalibrationSessions} />
                  <Route path="/app/admin/promotion-dashboard" exact component={AdminPromotionDashboard} />
                  <Route path="/app/admin/cycles/create" exact component={CreateCycle} />
                  <Route path="/app/admin/cycles/:cycleId" exact component={EditCycle} />
                  <Route path="/app/admin/cycles/:cycleId/upload-participants" exact component={ParticipantUpload} />
                  <Route path="/app/admin/cycles/:cycleId/upsert-participants" exact component={ParticipantUpsert} />
                  <Route path="/app/admin/cycles/:cycleId/upload-questionnaire" exact component={QuestionnaireUpload} />
                  <Route path="/app/admin/cycles/:cycleId/download-participant-jobs/:jobId" exact component={ParticipantDownload} />
                  <Route path="/app/admin/cycles/:cycleId/json-builder" exact component={JSONBuilder} />
                  <Route path="/app/admin/cycles/:cycleId/questions" exact component={ViewQuestions} />
                  <Route path="/app/admin/cycles/:cycleId/bulk-participants-feedback-pdf-download" exact component={BulkParticipantFeedbackPDFDownload} />
                  <Route path="/app/admin/cycles/:cycleId/participants/create" exact component={CreateParticipant} />
                  <Route path="/app/admin/cycles/:cycleId/participants/:email/edit" exact component={EditParticipant} />
                  <Route path="/app/admin/cycles/:cycleId/participants/:email/edit-rating" exact component={EditParticipantRating} />
                  <Route path="/app/admin/cycles/:cycleId/participants/:email/edit-promotion-decision" exact component={EditPromotionDecision} />
                  <Route path="/app/admin/cycles/:cycleId/participants/:email/panel" exact component={EditParticipantPanel} />
                  <Route path="/app/admin/cycles/:cycleId/participants" exact component={Participants} />
                  <Route path="/app/admin/cycles/:cycleId/upload-participants-jobs" component={ParticipantJobs} />
                  <Route path="/app/admin/cycles/:cycleId/upsert-participants-jobs" component={UpsertParticipantJobs} />
                  <Route path="/app/admin/cycles/:cycleId/populate-participants-jobs" component={PopulateParticipantsJobs} />
                  <Route path="/app/admin/cycles/:cycleId/upload-questionnaire-jobs" component={QuestionnaireJobs} />
                  <Route path="/app/admin/cycles/:cycleId/nudges" component={Nudges} />
                  <Route path="/app/admin/cycles/:cycleId/decision-support/reports" component={Reports} />
                  <Route path="/app/admin/cycles/:cycleId/therma-scan-report/upload" component={UploadThermaScanReport} />
                </Switch>
              </AdminCyclesProvider>
            </Route>
            <Route path="/app/admin/users" component={Users} />
            <Route path="/app/admin/audits" exact component={Audits} />
          </>
        ) : <div data-testid="not-access">You do not have access to this page</div>}

      </>
    )
  }

  return (
    <Router>
      <RouteConfigProvider>
        <AppLayout>
          <Suspense fallback={<Loader />}>
            <ToastProvider>
              <Switch>
                <Route path="/" exact render={() => (<Redirect to="/app/home" />)} />
                <Route path="/app/login" exact component={Login} />
                <Route path="/app/performance-dashboard" exact component={PerformanceDashboard} />
                <Route path="/app/home">
                  <CyclesProvider>
                    <ParticipantProvider />
                    <Switch>
                      <Route path="/app/home" exact component={PerformanceHome} />
                    </Switch>
                  </CyclesProvider>
                </Route>
                <Route path="/app/performance">
                  <CyclesProvider>
                    <ParticipantProvider>
                      <Switch>
                        <Route path="/app/performance/prep" exact component={CyclePrep} />
                        <Route path="/app/performance/peer-finalization" exact component={PeerFinalization} />
                        <Route path="/app/performance/spu-feedback/feedback/:revieweeEmail/view" exact component={ViewFeedback} />
                        <Route path="/app/performance/spu-feedback/feedback/:revieweeEmail" exact component={Feedback} />
                        <Route path="/app/performance/spu-feedback" exact component={SPUFeedback} />
                        <Route path="/app/performance/final-assessment/summary" exact component={ParticipantSummary} />
                        <Route path="/app/performance/final-assessment" exact component={FinalAssessment} />
                      </Switch>
                    </ParticipantProvider>
                  </CyclesProvider>
                </Route>
                <Route path="/app/my-reportees/:directReportEmail/feedback/summary" exact component={DirectReportFeedbackSummary} />
                <Route path="/app/my-reportees">
                  <CyclesProvider>
                    <ParticipantProvider>
                      <Switch>
                        <Route path="/app/my-reportees/:directReportEmail/peer-finalization" exact component={DirectReportPeerFinalization} />
                        <Route path="/app/my-reportees/:directReportEmail/feedback/:reviewerEmail" exact component={DirectReportViewFeedback} />
                        <Route path="/app/my-reportees/:revieweeEmail/feedback" exact component={Feedback} />
                        <Route path="/app/my-reportees/secondary/:secondaryReportEmail/feedback/summary" exact component={SecondaryReportFeedbackSummary} />
                        <Route path="/app/my-reportees/:participantEmail/promotion" exact component={NominationForm} />
                        <Route path="/app/my-reportees" component={MyReportees} />
                      </Switch>
                    </ParticipantProvider>
                  </CyclesProvider>
                </Route>
                <Route path="/app/pbp-dashboard">
                  <CyclesProvider>
                    <Switch>
                      <Route path="/app/pbp-dashboard/peer-selection" exact component={PBPDashboardPeerSelection} />
                      <Route path="/app/pbp-dashboard/feedback-stage" exact component={PBPDashboardFeedback} />
                    </Switch>
                  </CyclesProvider>
                </Route>
                <Route path="/app/session-admin">
                  <CyclesProvider>
                    <Switch>
                      <Route path="/app/session-admin/calibration-sessions" exact component={SessionAdminCalibrationSessionsList} />
                      <Route path="/app/session-admin/calibration-sessions/:id" component={CalibrationSessionDetails} />
                    </Switch>
                  </CyclesProvider>
                </Route>
                <Route path="/app/sign-off-party">
                  <CyclesProvider>
                    <Switch>
                      <Route path="/app/sign-off-party/calibration-sessions" exact component={SignOffpartyCalibrationSessionsList} />
                      <Route path="/app/sign-off-party/calibration-sessions/:id" component={SignOffPartyCalibrationSessionDetails} />
                    </Switch>
                  </CyclesProvider>
                </Route>
                <Route path="/app/promotions">
                  <Switch>
                    <Route path="/app/promotions/endorsements" exact component={Endorsement} />
                    <Route path="/app/promotions/endorsements/:endorsementId/endorse" exact component={EndorsementForm} />
                    <Route path="/app/promotions/endorsements/:endorsementId/view" exact component={ViewEndorsement} />
                    <CyclesProvider>
                      <Route path="/app/promotions/hr-partners/nominations" exact component={Nominations} />
                      <Route path="/app/promotions/hr-partners/create-session" exact component={CreateSesssion} />
                      <Route path="/app/promotions/hr-partners/sessions/:id/edit" exact component={EditSession} />
                      <Route path="/app/promotions/hr-partners/sessions/:id" exact component={CalibrationSessionDetail} />
                      <Route path="/app/promotions/calibration-panelist/sessions" exact component={PanelistPromotionCalibrationSessions} />
                      <Route path="/app/promotions/calibration-panelist/sessions/:id" exact component={PanelistCalibrationSessionDetails} />
                      <Route path="/app/promotions/sign-off-party/sessions" exact component={SignOffPartyPromotionCalibrationSessions} />
                      <Route path="/app/promotions/sign-off-party/sessions/:id" exact component={SignOffPartyPromotionCalibrationSessionDetails} />
                    </CyclesProvider>
                  </Switch>
                </Route>
                <Route path="/app/ratings">
                  <Switch>
                    <CyclesProvider>
                      <Route path="/app/ratings/hr-partners" exact component={HrPartnerCalibrationSessions} />
                      <Route path="/app/ratings/hr-partners/create-session" exact component={HrPartnerCreateRatingCalibrationSession} />
                      <Route path="/app/ratings/hr-partners/sessions/:id/edit" exact component={HrPartnerEditRatingCalibrationSession} />
                      <Route path="/app/ratings/calibration-panelist/sessions" exact component={PanelisRatingCalibrationSessions} />
                      <Route path="/app/ratings/calibration-panelist/sessions/:id" exact component={PanelistRatingCalibrationSessionDetails} />
                      <Route path="/app/ratings/calibration-panelist/sessions/:sessionId/participants/:participantId/calibration-rating" exact component={PanelistCalibrationRating} />
                    </CyclesProvider>
                  </Switch>
                </Route>
                <Route path="/app/anytime-feedback">
                  <Switch>
                    <Route path="/app/anytime-feedback" component={RealTimeFeedbacks} />
                  </Switch>
                </Route>
                <SuperAdminRoutes />
                <Route render={() => <PageNotFound />} />
              </Switch>
            </ToastProvider>
          </Suspense>
        </AppLayout>
      </RouteConfigProvider>
    </Router>
  )
}

export default AppRouter
