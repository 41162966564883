import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './locales/en-US.json'

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    nsSeparator: false,
    resources: {
      en: {
        translation: enUS,
      },
    },
  })

export default i18n
