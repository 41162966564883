const ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  PEOPLE_PARTNER: 'PEOPLE_PARTNER',
  HR_OPS: 'HR_OPS',
  EMPLOYEE: 'EMPLOYEE',
}

const ROLES = [
  { id: ROLE.SUPER_ADMIN, key: 'Super Admin' },
  { id: ROLE.PEOPLE_PARTNER, key: 'People Partner' },
  { id: ROLE.HR_OPS, key: 'HR Ops' },
  { id: ROLE.EMPLOYEE, key: 'Employee' },
]

export { ROLES, ROLE }
