import request from './request'
import RailsRouter from '../RailsRouter.js.erb'

export const getUserCoachmarks = () => request({
  method: 'GET',
  url: RailsRouter.userCoachmarksPath(),
})

export const createUserCoachmarks = (coachmark) => request({
  method: 'POST',
  url: RailsRouter.userCoachmarksPath(),
  data: coachmark,
})
