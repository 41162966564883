import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './styles/theme'
import AppRouter from './routes'
import ConfigProvider from './contexts/config'
import ErrorBoundary from './components/ErrorBoundary'
import UserProvider from './contexts/User'

export default function App() {
  return (
    <ConfigProvider>
      <ErrorBoundary>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRouter />
          </ThemeProvider>
        </UserProvider>
      </ErrorBoundary>
    </ConfigProvider>
  )
}
