import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

type LoaderProps = {
  loaderText? : string
}

const Loader: React.FC<LoaderProps> = ({ loaderText }) => (
  <Typography align="center" component="div">
    <CircularProgress />
    {loaderText && <div>{loaderText}</div>}
  </Typography>
)

Loader.defaultProps = {
  loaderText: '',
}

export default Loader
