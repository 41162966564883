import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { GA_GTM_ID, GTM_PROJECT, EVENTS } from '../constants/analytics'

export const initializeGoogleAnalytics = (config, user) => {
  const tagManagerArgs = {
    gtmId: GA_GTM_ID,
    dataLayer: {
      userId: user ? user.id : '',
      userProject: GTM_PROJECT,
      environment: config.DEPLOYMENT_ENVIRONMENT,
    },
  }

  TagManager.initialize(tagManagerArgs)

  ReactGA.initialize(config.GA_GTAG_ID, {
    debug: false,
    gaOptions: {
      userId: user ? user.id : '',
    },
  })
}

export const registerGoogleAnalyticsWithHistory = (history) => {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  })
}

export const createEvent = (event) => {
  const tagManagerArgs = {
    dataLayer: {
      category: event.category,
      action: event.action,
      event: EVENTS.userEvent,
      label: event.label,
      cycleId: event.cycleId,
      cycleState: event.cycleState,
      viewerType: event.viewerType,
    },
  }

  TagManager.dataLayer(tagManagerArgs)
}
