import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined'
import { useLocation } from 'react-router-dom'
import { createUserFeedback } from '../../api/users'
import { getUserCoachmarks, createUserCoachmarks } from '../../api/coachmarks'
import { RouteConfigContext } from '../../contexts/RouteConfig'

export const userFeedbackCoachmarkType = 'user_feedback'

const useStyles = makeStyles((theme) => ({
  dialogBox: {
    width: 550,
  },
  redButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  helpButton: {
    position: 'fixed',
    bottom: '50%',
    right: -39,
    padding: '5px 12px',
    zIndex: 10,
    transform: 'rotate(270deg)',
    backgroundColor: '#df1995',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#df1995',
    },
  },
}))

const UserFeedback = () => {
  const { routeOptions } = useContext(RouteConfigContext)
  const [isOpen, setIsOpen] = useState(false)
  const [feedbackText, setFeedbackText] = useState('')
  const [hasLikeUsingApp, setHasLikeUsingApp] = useState(null)
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [skipCoachmarkPresent, setSkipCoachmarkPresent] = useState(false)
  const [isLoadingCoachMark, setIsLoadingCoachMark] = useState(true)
  const [hasGivenFeedback, setHasGivenFeedback] = useState(routeOptions['has_user_feedback?'])
  const location = useLocation()
  const classes = useStyles()

  const anytimeFeedbackPage = location.pathname === '/app/anytime-feedback'

  useEffect(() => {
    getUserCoachmarks().then((resp) => {
      const userOnMyReportPage = location.pathname === '/app/performance/final-assessment'
      const hasFeedbackCoachmark = resp.coachmarks.map((c) => c.coachmark_type).includes(userFeedbackCoachmarkType)
      if (!hasFeedbackCoachmark && userOnMyReportPage && !hasGivenFeedback) {
        setIsOpen(true)
      }
      setSkipCoachmarkPresent(hasFeedbackCoachmark)
      setIsLoadingCoachMark(false)
    })
  }, [])

  useEffect(() => {
    if (!isLoadingCoachMark) {
      const userOnMyReportPage = location.pathname === '/app/performance/final-assessment'
      if (!skipCoachmarkPresent && userOnMyReportPage && !hasGivenFeedback) {
        setIsOpen(true)
      }
    }
  }, [location])

  const handleDialogClose = () => {
    setIsOpen(false)
  }

  const resetFeedbackForm = () => {
    setIsOpen(false)
    setIsSubmitting(false)
    setHasLikeUsingApp(null)
    setFeedbackText('')
  }

  const handleButtonClick = (value) => {
    setHasLikeUsingApp(value)
    setError('')
  }

  const handleSubmitClick = () => {
    if (hasLikeUsingApp !== null) {
      const data = {
        has_liked: hasLikeUsingApp,
        text: feedbackText,
      }
      setIsSubmitting(true)
      createUserFeedback(data).then(() => {
        resetFeedbackForm()
        setHasGivenFeedback(true)
      })
    } else {
      setError('Please select one option')
    }
  }

  const handleSkipClick = () => {
    if (!skipCoachmarkPresent) {
      createUserCoachmarks({ coachmark_type: userFeedbackCoachmarkType, is_dismissed: true })
    }
    handleDialogClose()
  }

  if (anytimeFeedbackPage) {
    return <></>
  }

  return (
    <>
      <Button
        startIcon={<FeedbackOutlinedIcon />}
        className={classes.helpButton}
        onClick={() => setIsOpen(true)}
        variant="contained"
      >
        Feedback
      </Button>
      <Dialog open={isOpen} onClose={handleSkipClick}>
        <DialogTitle id="form-start-cycle">User Feedback</DialogTitle>
        <DialogContent className={classes.dialogBox}>
          <Typography variant="subtitle1">Do you like using 360?</Typography>
          <Box mt={1}>
            <Button
              startIcon={<ThumbDownIcon color={hasLikeUsingApp === false ? 'error' : 'inherit'} />}
              variant="outlined"
              onClick={() => handleButtonClick(false)}
              className={hasLikeUsingApp === false ? classes.redButton : ''}
            >
              No
            </Button>
            <Button
              color={hasLikeUsingApp === true ? 'primary' : 'default'}
              startIcon={<ThumbUpIcon color={hasLikeUsingApp === true ? 'primary' : 'inherit'} />}
              variant="outlined"
              onClick={() => handleButtonClick(true)}
              className="ml-10"
            >
              Yes
            </Button>
            {error && <Typography color="error">{error}</Typography>}
          </Box>
          <Box mt={5}>
            <Typography variant="subtitle1">
              In case you have additional feedback please add it on the comment box below
            </Typography>
            <Box mt={1}>
              <TextField
                id="user-feedback"
                label="feedback(optional)"
                multiline
                minRows={4}
                maxRows={15}
                variant="outlined"
                fullWidth
                onChange={(e) => setFeedbackText(e.target.value)}
                value={feedbackText}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSkipClick}>Skip</Button>
          <Button onClick={handleSubmitClick} color="primary" disabled={isSubmitting}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UserFeedback
