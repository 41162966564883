import { Step } from 'react-joyride'

export interface CoachmarkStep extends Step {
  coachmarkType: string,
}

export const directReportFeedbackSummaryCycleSwitcherSteps: CoachmarkStep[] = [
  {
    target: '.cycle-switcher',
    coachmarkType: 'direct_report_feedback_summary_cycle_switcher',
    disableBeacon: true,
    hideCloseButton: true,
    content: `Use this drop-down to see your direct report’s 
              performance feedback from past performance review cycles`,
  },
]

export const multipleCyclesSteps: CoachmarkStep[] = [
  {
    target: '.cycle-switcher',
    coachmarkType: 'multiple_active_cycle_switcher',
    disableBeacon: true,
    hideCloseButton: true,
    content: 'You are part of multiple active cycles. Use this drop-down to see other cycles',
  },
]

export const peerStakeholderSelectionSteps: CoachmarkStep[] = [
  {
    target: '.peer-selection',
    coachmarkType: 'peer_stakeholder_selection',
    disableBeacon: true,
    hideCloseButton: true,
    content: 'You can now give and receive feedback from all peers/stakeholders from all Business Units across GoTo.',
  },
]

export const promotionCalibrationPanelistSteps: CoachmarkStep[] = [
  {
    target: '.calibration-session-details-action-header',
    coachmarkType: 'promotion_calibration_panelist',
    disableBeacon: true,
    hideCloseButton: true,
    content: 'You can now read nominated employee’s feedback summary report',
  },
]

export const directReportFeedbackSteps: CoachmarkStep[] = [
  {
    target: '.direct-report-feedback',
    coachmarkType: 'direct_report_feedback',
    disableBeacon: true,
    hideCloseButton: true,
    content: `You can now read feedback summary of direct reports 
              while writing manager feedback`,
  },
]

export const sidebarRoutesSteps: CoachmarkStep[] = [
  {
    target: '.rating-calibration-hr-partner',
    coachmarkType: 'sidebar_routes_v1',
    disableBeacon: true,
    hideCloseButton: true,
    content: 'You can now create and activate rating calibrations on the 360 tool',
  },
  {
    target: '.rating-calibration-panelist',
    coachmarkType: 'sidebar_routes_v1',
    disableBeacon: true,
    hideCloseButton: true,
    content: 'You can now review rating calibrations on the 360 tool',
  },
]
