/* eslint max-len: ["error", { "code": 118 }] */
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({

  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
  palette: {
    common: {
      black: '#1b2842',
      white: '#fff',
    },
    type: 'light',
    primary: {
      main: '#00aa13',
      light: '#33bb42',
      dark: '#00880f',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2d446f',
      light: '#57698b',
      dark: '#243658',
      contrastText: '#fff',
    },
    error: {
      light: '#fdecea',
      main: '#de3509',
      dark: '#b12a07',
      contrastText: '#fff',
    },
    warning: {
      light: '#fff4e5',
      main: '#ff991f',
      dark: '#cc7a18',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      light: '#edf7ed',
      main: '#028759',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
      primary: '#1b2842',
      secondary: '#243658',
      disabled: '#9d9d9d',
      hint: '#8292b0',
    },
    divider: '#eaecf0',
    background: {
      paper: '#fff',
      default: '#fff',
      level2: '#f9f9f9',
      level1: '#fff',
    },
    action: {
      active: 'rgb(84, 101, 132)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    grey: {
      300: '#eaecf0',
    },
  },
  props: {},
  shadows: [
    'none',
    '0px 0px 16px 2px rgba(130, 146, 176, 0.2)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Maison Neue',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '2.25rem',
      lineHeight: 1.45,
      letterSpacing: '0em',
    },
    h2: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 1.375,
      letterSpacing: '0em',
    },
    h3: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '1.75rem',
      lineHeight: 1.357,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.333,
      letterSpacing: '0em',
    },
    h5: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '1.3125rem',
      lineHeight: 1.4,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.333,
      letterSpacing: '0em',
    },
    subtitle1: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.35,
      letterSpacing: '0em',
    },
    subtitle2: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0em',
    },
    body1: {
      fontFamily: 'Maison Neue',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: 'Maison Neue',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0em',
      textTransform: '',
      borderRadius: 4,
    },
    caption: {
      fontFamily: 'Maison Neue',
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.66,
      letterSpacing: '0',
    },
    overline: {
      fontFamily: 'Maison Neue',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 4,
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderColor: '#9ba7bf',
        '& $notchedOutline': {
          borderColor: '#8292b0',
        },
        '&:hover $notchedOutline': {
          color: '#8292b0',
          borderColor: '#8292b0',
          borderWidth: '1px',
        },
        '&$focused $notchedOutline': {
          color: '#8292b0',
          borderColor: '#8292b0',
          borderWidth: '1px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#8292b0',
        '&$focused': {
          color: '#8292b0',
          borderColor: '#8292b0',
          borderWidth: '1px',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #eaecf0',
      },
      head: {
        fontWeight: 600,
      },
    },
    MuiButton: {
      root: {
        color: '#8292b0',
        padding: '7px 24px',
      },
      outlined: {
        borderColor: '#8292b0',
        padding: '7px 24px',
      },
      contained: {
        boxShadow: 'none',
        padding: '7px 24px',
      },
      text: {
        padding: '6px 16px',
      },
    },
    MuiRadio: {
      root: {
        color: '#8292b0',
      },
    },
    MuiCardContent: {
      root: {
        padding: 24,
      },
    },
    MuiChip: {
      root: {
        color: '#1b2842',
        fontSize: '0.875rem',
      },
    },
    MuiFormHelperText: {
      root: {
        fontWeight: 400,
      },
    },
    MuiStepper: {
      root: {
        padding: '24px 0',
      },
    },
    MuiStepIcon: {
      root: {
        color: '#8292b0',
      },
    },
  },
})

export default theme
