import React, {
  useState, createContext, useEffect, useContext,
} from 'react'
import { useLocation } from 'react-router-dom'
import { getCycles } from '../api/cycle'
import { ROLE } from '../constants'
import Loader from '../components/common/Loader'
import ShowError from '../components/common/ShowError'
import { UserContext } from './User'

export const AdminCyclesContext = createContext({})

export function AdminCyclesProvider({ children }) {
  const [isLoadingCycles, setIsLoadingCycles] = useState(true)
  const [cycles, setCycles] = useState([])
  const { user } = useContext(UserContext)
  const isAllowed = [ROLE.SUPER_ADMIN, ROLE.HR_OPS].includes(user.role)
  const [loadingCyclesError, setLoadingCyclesError] = useState(null)
  const [currentCycle, setCurrentCycle] = useState(null)
  const location = useLocation()
  useEffect(() => {
    if (isAllowed) {
      getCycles().then((res) => {
        setCurrentCycle(res.cycles[0])
        setCycles(res.cycles)
        setIsLoadingCycles(false)
      }).catch((error) => {
        setLoadingCyclesError(error)
        setIsLoadingCycles(false)
      })
    }
  }, [])

  const getCycleByCycleId = (
    adminCycles, cycleId,
  ) => adminCycles.find((cycle) => cycle.id === cycleId)

  const searchParams = new URLSearchParams(location.search)

  const handleUpdateCycle = (updatedCycle) => {
    const updatedCycles = cycles.map((cycle) => {
      const urlCycleId = parseInt(searchParams.get('cycle_id'), 10)
      const currentCycleId = currentCycle ? currentCycle.id : null
      if (urlCycleId !== currentCycleId) {
        const defaultCycle = urlCycleId ? getCycleByCycleId(cycles, urlCycleId)
          : currentCycle || cycles[0]
        setCurrentCycle(defaultCycle)
      }
      if (cycle.id === updatedCycle.id) {
        return updatedCycle
      }
      return cycle
    })
    setCycles(updatedCycles)
  }

  useEffect(() => {
    if (!isLoadingCycles) {
      handleUpdateCycle(cycles)
    }
  }, [location.search])

  const addNewCycle = (cycleData) => {
    setCycles([...cycles, cycleData])
  }

  const deleteCycle = (cycleData) => {
    const newCycles = cycles.filter((cycle) => (cycle.id !== cycleData.id))
    setCycles(newCycles)
  }

  const getCycleById = (id) => cycles.find((cycle) => cycle.id === id)

  return (
    <>
      {isAllowed ? (
        <AdminCyclesContext.Provider
          value={{
            cycles,
            currentCycle,
            setCurrentCycle,
            handleUpdateCycle,
            getCycleById,
            addNewCycle,
            deleteCycle,
          }}
        >
          {isLoadingCycles
            ? <Loader />
            : (
              <>
                {loadingCyclesError ? <ShowError error={loadingCyclesError} />
                  : <>{children}</>}
              </>
            )}
        </AdminCyclesContext.Provider>
      ) : <div>You do not have access to this page</div>}
    </>

  )
}
