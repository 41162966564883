import request from './request'
import RailsRouter from '../RailsRouter.js.erb'

export const loginUser = (token) => request({
  method: 'POST',
  url: RailsRouter.tokensPath(),
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

export const loginLarkUser = (code) => request({
  method: 'POST',
  url: RailsRouter.larkTokensPath(),
  data: {
    code,
  },
})

export const logoutUser = () => request({
  method: 'DELETE',
  url: RailsRouter.tokenLogoutPath(),
})

export const getUser = (id) => request({
  method: 'GET',
  url: RailsRouter.adminUserPath(id),
})

export const getUsers = (query) => request({
  method: 'GET',
  url: RailsRouter.adminUsersPath(query),
})

export const employeeGetUsers = (query) => request({
  method: 'GET',
  url: RailsRouter.usersPath(query),
})

export const employeeGetUsersHasDirectReport = () => request({
  method: 'GET',
  url: RailsRouter.usersHasDirectReportPath(),
})

export const editUser = (id, data) => request({
  method: 'PATCH',
  url: RailsRouter.adminUserPath(id),
  data,
})

export const spoofUser = (email) => request({
  method: 'POST',
  url: RailsRouter.spoofUserPath(),
  data: {
    spoofed_user_email: email,
  },
})

export const undoSpoofUser = () => request({
  method: 'DELETE',
  url: RailsRouter.spoofUserPath(),
})

export const createUserFeedback = (data) => request({
  method: 'POST',
  url: RailsRouter.createUserFeedback(),
  data,
})
