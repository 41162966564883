import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BookIcon from '@material-ui/icons/Book'
import CommentIcon from '@material-ui/icons/Comment'
import DashboardIcon from '@material-ui/icons/Dashboard'
import EditIcon from '@material-ui/icons/Edit'
import ForumIcon from '@material-ui/icons/Forum'
import GradeIcon from '@material-ui/icons/Grade'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import PlayCircleIcon from '@material-ui/icons/PlayCircleOutline'
import RateReviewIcon from '@material-ui/icons/RateReview'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import SettingsIcon from '@material-ui/icons/Settings'
import StarsIcon from '@material-ui/icons/Stars'
import BarChartIcon from '@material-ui/icons/BarChart'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import StarHalfIcon from '@material-ui/icons/StarHalf'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import WorkIcon from '@material-ui/icons/Work'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import i18n from './translation'
import { ROLE, ROLES } from '../constants'

const rolesIds = ROLES.map((role) => role.id)

const routeConfig = [
  {
    pathname: '/app/admin',
    title: 'Super Admin',
    allowedUser: [ROLE.SUPER_ADMIN],
    icon: SettingsIcon,
    subRoutes: [
      {
        pathname: '/app/admin/users',
        icon: PeopleAltIcon,
        title: i18n.t('ROUTE_TITLE--USERS'),
      },
      {
        pathname: '/app/admin/audits',
        icon: SupervisedUserCircleIcon,
        title: i18n.t('ROUTE_TITLE--AUDIT'),
      },
      {
        pathname: '/app/admin/cycles',
        icon: RotateLeftIcon,
        title: i18n.t('ROUTE_TITLE--CYCLES'),
      },
      {
        pathname: '/app/admin/calibration-sessions',
        icon: StarsIcon,
        title: 'Calibration Sessions',
      },
      {
        pathname: '/app/admin/promotion-dashboard',
        icon: StarHalfIcon,
        title: 'Promotion Dashboard',
      },
    ],
  },
  {
    pathname: '/app/home',
    title: 'Home',
    allowedUser: [...rolesIds],
    icon: HomeIcon,
  },
  {
    title: 'Performance Cycle',
    allowedUser: [...rolesIds],
    icon: DashboardIcon,
    infoText: 'Click to participate in Performance Cycle',
    subRoutes: [
      {
        pathname: '/app/performance/prep',
        title: 'Check your profile',
        icon: PersonIcon,
        infoText: 'Review your profile and team details for Performance Cycle',
      },
      {
        pathname: '/app/performance/peer-finalization',
        title: 'Peer/Stakeholder Selection',
        icon: PeopleAltIcon,
        infoText: 'Click to select peers/stakeholders you would like to receive feedback from',
      },
      {
        pathname: '/app/my-reportees/peer-finalization',
        title: i18n.t('ROUTE_TITLE--MANAGER_MODIFY_MY_TEAM_PEER'),
        routeAllowed: 'has_direct_report?',
        icon: SettingsIcon,
        infoText: 'Modify peers/stakeholders for your team',
      },
      {
        pathname: '/app/performance/spu-feedback',
        title: i18n.t('ROUTE_TITLE--360_FEEDBACK'),
        icon: EditIcon,
        infoText: 'Click to complete your self review and complete feedback for manager and peers/stakeholders',
      },
      {
        pathname: '/app/my-reportees/feedbacks',
        title: i18n.t('ROUTE_TITLE--MANAGER_FEEDBACK_AND_PROMOTION_NOMINATION'),
        routeAllowed: 'has_direct_report?',
        icon: CommentIcon,
        infoText:
          'Click to complete feedback for your direct reports and nominate selected direct reports for promotion',
      },
    ],
  },
  {
    title: 'Rating Calibration',
    allowedUser: [...rolesIds],
    icon: StarHalfIcon,
    infoText: 'Click to manage or participate in rating calibrations process',
    routeAllowed: 'rating_calibration?',
    subRoutes: [
      {
        pathname: '/app/ratings/hr-partners',
        title: 'Create & Activate Calibration Session',
        icon: AssignmentIcon,
        routeAllowed: 'hr_partner_has_employees?',
        infoText: 'For People Business Partners: Click to create and activate session',
        coachMarkClassName: 'rating-calibration-hr-partner',
      },
      {
        pathname: '/app/session-admin/calibration-sessions',
        title: 'Run Calibration Session',
        allowedUser: [...rolesIds],
        icon: PlayCircleIcon,
        routeAllowed: 'session_admin?',
        infoText: 'For Session Admins: Click to run session',
      },
      {
        pathname: '/app/ratings/calibration-panelist/sessions',
        title: 'Review Your Calibration Session',
        icon: ForumIcon,
        routeAllowed: 'panelists?',
        infoText: 'For All Session Panelists: Click to review calibration session',
        coachMarkClassName: 'rating-calibration-panelist',
      },
      {
        pathname: '/app/sign-off-party/calibration-sessions',
        title: 'Sign-off Rating Calibration Session',
        allowedUser: [...rolesIds],
        icon: RateReviewIcon,
        routeAllowed: 'sign_off_party?',
        infoText: 'For Sign Off Party: Click to sign off ratings calibration session',
      },
    ],
  },
  {
    title: 'Promotions Calibration',
    allowedUser: [...rolesIds],
    icon: WorkIcon,
    routeAllowed: 'promotions?',
    infoText: 'Click to participate or manage promotion calibrations',
    subRoutes: [
      {
        pathname: '/app/promotions/endorsements',
        title: i18n.t('ROUTE_TITLE--ENDORSEMENTS'),
        icon: ThumbUpIcon,
        routeAllowed: 'promotion_endorser?',
      },
      {
        pathname: '/app/promotions/hr-partners/nominations',
        title: 'Run Promotion Calibration Session',
        icon: PlayCircleIcon,
        routeAllowed: 'promotion_session_admin?',
        infoText: 'For Session Admins: Click to run session',
      },
      {
        pathname: '/app/promotions/calibration-panelist/sessions',
        title: 'Review Your Promotion Calibration Session',
        icon: ForumIcon,
        routeAllowed: 'promotion_panelist?',
        infoText: 'For All Session Panelists: Click to review promotion calibration session',
      },
      {
        pathname: '/app/promotions/sign-off-party/sessions',
        title: 'Sign-off Promotion Calibration Session',
        icon: RateReviewIcon,
        routeAllowed: 'promotion_sign_off_party?',
        infoText: 'For Sign Off Party: Click to sign off promotions',
      },
    ],
  },
  {
    title: 'PBP Dashboard',
    allowedUser: [...rolesIds],
    icon: BarChartIcon,
    infoText: 'PBP Dashboard',
    subRoutes: [
      {
        pathname: '/app/pbp-dashboard/peer-selection',
        title: 'Peer Selection and Manager Approval',
        icon: ShowChartIcon,
        infoText: 'Peer Selection and Manager Approval',
      },
      {
        pathname: '/app/pbp-dashboard/feedback-stage',
        title: 'Feedback Stage',
        icon: ShowChartIcon,
        infoText: 'Feedback Stage',
      },
    ],
  },
  {
    title: 'Performance Report',
    allowedUser: [...rolesIds],
    icon: AssessmentIcon,
    subRoutes: [
      {
        pathname: '/app/performance/final-assessment',
        icon: GradeIcon,
        title: 'Performance Cycle Report',
      },
      {
        pathname: '/app/performance-dashboard',
        icon: TrendingUpIcon,
        title: 'Performance Trends Report',
      },
    ],
    infoText: 'Click to view performance cycle reports and performance trends over cycles',
  },
  {
    title: 'Guidelines',
    allowedUser: [...rolesIds],
    icon: BookIcon,
    subRoutes: [
      {
        pathname: 'https://sites.google.com/go-jek.com/performance-portal/home',
        title: 'General Guideline',
        externalLink: true,
      },
    ],
  },
  {
    pathname: '/app/anytime-feedback',
    title: 'Anytime Feedback',
    allowedUser: [...rolesIds],
    icon: AccessTimeIcon,
  },
]

const getUserRoutesConfig = (userRole, routeOptions, config) => (
  routeConfig.map((route) => {
    let { subRoutes } = route
    if (subRoutes) {
      subRoutes = route.subRoutes.filter((subRoute) => {
        const subRouteAllowed = subRoute.routeAllowed ? routeOptions[subRoute.routeAllowed] : true
        return subRouteAllowed
      })
      return { ...route, subRoutes }
    }
    return route
  }).filter((route) => {
    const routeAllowed = route.routeAllowed ? routeOptions[route.routeAllowed] : true
    const featureFlagEnabled = route.featureFlag ? config[route.featureFlag] : true
    return (route.allowedUser.includes(userRole) && routeAllowed && featureFlagEnabled)
  })
)

export default getUserRoutesConfig
