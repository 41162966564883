// Erwin spent 3 hours fixing a bug related to this tech debt.
// FIXME:  This participant data is copied over to directReportFeedbacks obj.
//         Any changes in participant object will not re-render the directReportFeedbacks props.
//         Consider using Redux to store this participant data as it is being used in multiple pages.
import React, {
  useState, createContext, useEffect, useContext,
} from 'react'
import { getParticipantForCycle } from '../api/participant'
import Loader from '../components/common/Loader'
import { CyclesContext } from './cycle'
import { UserContext } from './User'

export const ParticipantContext = createContext({})

export function ParticipantProvider({ children }) {
  const [isLoadingParticipant, setIsLoadingParticipant] = useState(true)
  const [participant, setParticipant] = useState(null)
  const [errorLoadingParticipant, setErrorLoadingParticipant] = useState(false)
  const { currentCycle } = useContext(CyclesContext)
  const { user } = useContext(UserContext)

  const fetchParticipant = (cycleId) => {
    getParticipantForCycle(cycleId, user.email).then((res) => {
      setParticipant(res.participant)
      setIsLoadingParticipant(false)
    }).catch(() => {
      setErrorLoadingParticipant(true)
      setIsLoadingParticipant(false)
    })
  }

  useEffect(() => {
    setIsLoadingParticipant(true)
    fetchParticipant(currentCycle.id)
  }, [currentCycle.id])

  const updateDirectReports = ({ email, promotioNomination }) => {
    const updatedDirectReports = participant.direct_reports.map((directReport) => {
      if (directReport.email === email) {
        return { ...directReport, promotion_nomination: promotioNomination }
      }
      return directReport
    })
    setParticipant({ ...participant, direct_reports: updatedDirectReports })
  }

  return (
    <ParticipantContext.Provider
      value={{ participant, isLoadingParticipant, updateDirectReports }}
    >
      {isLoadingParticipant
        ? <Loader />
        : (
          <>
            {errorLoadingParticipant
              ? <div>Error Loading data</div>
              : (
                <>{children}</>
              )}
          </>
        )}
    </ParticipantContext.Provider>
  )
}
