import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { Box, Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles(() => ({
  dialogBox: {
    width: 550,
    padding: 35,
  },
  helpButton: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    borderRadius: '30px 8px 30px 30px',
    padding: '5px 12px',
    zIndex: 10,

  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
}))

export default function Help() {
  const classes = useStyles()
  const [openHelp, setOpenHelp] = useState(false)

  return (
    <>
      <Button
        color="primary"
        startIcon={<HelpOutlineIcon />}
        className={classes.helpButton}
        onClick={() => setOpenHelp(true)}
        variant="contained"
      >
        Help
      </Button>
      <Dialog open={openHelp} onClose={() => setOpenHelp(false)}>
        <DialogContent className={classes.dialogBox}>
          <IconButton className={classes.closeButton} onClick={() => setOpenHelp(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box mb={2} mt={1}>
            <Typography component="h5" variant="h5" align="center">Suggested Articles</Typography>
          </Box>
          <Box>
            <Box mb={2}>
              <Link
                href="https://sites.google.com/go-jek.com/performance-portal/getting-started/feedback-101"
                target="_blank"
              >
                <Button color="primary" variant="outlined" fullWidth>
                  Feedback 101
                </Button>
              </Link>
            </Box>
          </Box>
          <Box>
            {'For queries, please raise a ticket through '}
            <Link
              href="https://onegoto.gotocompany.com/s/request-form-pac?formId=a0B5g000004hZ1IEAU&formName=Performance%20Query&formDept=PAC"
              target="_blank"
            >
              <strong>OneGoto</strong>
            </Link>
            {' > Employee Services > Raise a Ticket > People & Culture  > PAC Performance Query. '}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
