import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Styles from './index.module.scss'

const ToastContext = React.createContext(null)

let id = 1

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const addToast = React.useCallback((content) => {
    setToasts([...toasts, { id: id += 1, ...content }])
  }, [setToasts])

  const removeToast = React.useCallback((toastId, callbackOnClosed) => {
    setToasts((currentToasts) => currentToasts.filter((t) => t.id !== toastId))
    if (typeof callbackOnClosed === 'function') callbackOnClosed();
  }, [setToasts])

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
}

function useToast() {
  const toastHelpers = React.useContext(ToastContext);
  return toastHelpers
}

const Toast = ({
  id: tId, content, type, title, callbackOnClosed,
}) => {
  const [visible, setVisible] = useState(false)
  const { removeToast } = useToast()

  React.useEffect(() => {
    const enterTimer = setTimeout(() => {
      setVisible(true)
    }, 1)
    const leaveTimer = setTimeout(() => {
      setVisible(false)
      removeToast(tId, callbackOnClosed)
    }, 5000)
    return () => {
      clearTimeout(leaveTimer)
      clearTimeout(enterTimer)
    }
  }, [tId, removeToast])

  return (
    <div className="toast">
      <div className={Styles.panel}>
        <div
          className={`${Styles.animateFlag} ${visible ? Styles.visible : ''}`}
        >
          <Alert
            variant="filled"
            onClose={() => removeToast(tId, callbackOnClosed)}
            severity={type}
            data-testid="toast-id"
          >
            {title && <AlertTitle>{title}</AlertTitle>}
            {content}
          </Alert>
        </div>
      </div>
    </div>
  )
}

const ToastContainer = ({ toasts }) => ReactDOM.createPortal(
  <div>
    {toasts.map((item) => <Toast key="toast-mandatory-key" {...item} />)}
  </div>,
  document.body,
)

export { ToastContext, useToast }
export default ToastProvider
