import * as Sentry from '@sentry/react'

const initializeSentry = (user) => {
  Sentry.setUser({
    name: user ? user.name : '',
    email: user ? user.email : '',
    userId: user ? user.email : '',
  })
}

export default initializeSentry
