import request from './request'
import RailsRouter from '../RailsRouter.js.erb'

export const getConfig = () => request({
  method: 'GET',
  url: RailsRouter.configPath(),
})

export const getRouteConfig = () => request({
  method: 'GET',
  url: RailsRouter.routeConfigPath(),
})
