import request from './request'
import RailsRouter from '../RailsRouter.js.erb'

export const getParticipantForCycle = (cycleId:number, participantEmail:string) => request({
  method: 'GET',
  url: RailsRouter.cycleParticipantPath(cycleId, participantEmail),
})

export const searchParticipantsForCycle = (cycleId:number, query:string) => request({
  method: 'GET',
  url: RailsRouter.cycleParticipantsPath(cycleId, query),
})

export const adminSearchParticipants = (
  cycleId:number, searchQuery:string,
) => request({
  method: 'GET',
  url: RailsRouter.adminCycleParticipantsPath(cycleId, searchQuery),
})

export const adminGetParticipant = (
  cycleId:number, participantEmail:string,
) => request({
  method: 'GET',
  url: RailsRouter.adminCycleParticipantPath(cycleId, participantEmail),
})

export const adminUpdateParticipant = (
  cycleId:number, participantEmail:string, participantData:object,
) => request({
  method: 'PATCH',
  url: RailsRouter.adminCycleParticipantPath(cycleId, participantEmail),
  data: participantData,
})

export const adminCreateParticipant = (
  cycleId:number, participantData:object,
) => request({
  method: 'POST',
  url: RailsRouter.adminCycleParticipantsPath(cycleId),
  data: participantData,
})

export const adminDeleteParticipant = (
  cycleId:number, email:string,
) => request({
  method: 'DELETE',
  url: RailsRouter.adminCycleParticipantPath(cycleId, email),
})

export const adminUpdatePromotionDecision = (
  cycleId:number, participantEmail:string, promotionDecisionData: object,
) => request({
  method: 'PATCH',
  url: RailsRouter.adminCycleParticipantUpdatePromotionDecisionPath(cycleId, participantEmail),
  data: promotionDecisionData,
})

export const getAdminUploadParticipantJobs = (cycleId:number) => request({
  method: 'GET',
  url: RailsRouter.adminCycleUploadParticipantJobs(cycleId),
})

export const createAdminUploadParticipantJobs = (cycleId:number, formData:FormData) => request({
  method: 'POST',
  url: RailsRouter.adminCycleUploadParticipantJobs(cycleId),
  data: formData,
  'Content-Type': 'multipart/form-data',
})

export const getAdminUpsertParticipantJobs = (cycleId:number) => request({
  method: 'GET',
  url: RailsRouter.adminCycleUpsertParticipantJobs(cycleId),
})

export const createAdminUpsertParticipantJobs = (cycleId:number, formData:FormData) => request({
  method: 'POST',
  url: RailsRouter.adminCycleUpsertParticipantJobs(cycleId),
  data: formData,
  'Content-Type': 'multipart/form-data',
})

export const getParticipantDashboard = (cycleId:number) => request({
  method: 'GET',
  url: RailsRouter.getParticipantDashboardPath(cycleId),
})

export const createAdminDownloadParticipantJob = (cycleId:number, query:string) => request({
  method: 'POST',
  url: RailsRouter.adminCycleDownloadParticipantJobs(cycleId, query),
})

export const getAdminDownloadParticipantJob = (cycleId:number, jobId:number) => request({
  method: 'GET',
  url: RailsRouter.getAdminCycleDownloadParticipantJob(cycleId, jobId),
  'Content-Type': 'multipart/form-data',
})
