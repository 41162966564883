/* eslint-disable camelcase */
import React, {
  useEffect, createContext, ReactChild, ReactFragment, ReactPortal, useState, useContext,
} from 'react'
import storage from '../utils/storage'
import { initializeGoogleAnalytics } from '../utils/GoogleAnalytics'
import initializeSentry from '../utils/sentry'
import { ConfigContext } from './config'

export type User = {
  created_at: string
  email: string
  google_id: string
  id: string
  name: string
  profile_image_url: string
  session_id: string
  spoofed_user_id: string
  token: string
  }

  type UserProviderProps = {
    children: ReactChild | ReactFragment | ReactPortal
  }

export const UserContext = createContext(null)

export default function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState(storage.get('user'))
  const config = useContext(ConfigContext)

  const updateUser = (userData:User) => {
    setUser(userData)
    storage.set('Auth-Token', userData.token)
    storage.set('user', userData)
  }

  const removeUser = () => {
    setUser(null)
  }

  const initApp = () => {
    initializeGoogleAnalytics(config, user)
    initializeSentry(user)
  }

  const clearStorage = () => {
    storage.remove('Auth-Token')
    storage.remove('user')
    storage.remove('realUser')
  }

  useEffect(() => {
    if (!user) {
      clearStorage()
    } else {
      initApp()
    }
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user, updateUser, removeUser,
      }}
    >
      { children }
    </UserContext.Provider>
  )
}
